.mission {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(0 130px 0 260px);
  margin: rem(100px 0);
  background: cl(gray-bg);
  gap:rem(70px);
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding: rem(50px);
    margin: rem(50px 0);
  }
  @include media-breakpoint-down(xs) {
    gap:2rem;
    padding: 2rem;
  }
  &__image {
    width:100%;
    height: rem(400px);
    flex-shrink: 0;
    @include media-breakpoint-up(sm) {
      width:rem(428px);
    }
    @include media-breakpoint-up(xl) {
      margin: rem(-70px 0);
      width:rem(628px);
      height: rem(600px);
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__text {
    font-size: rem(28px);
    line-height: 1.4;
    font-family: $font;
    color:cl(primary);
    @include media-breakpoint-down(xs) {
      font-size: rem(24px);
    }
    ul {
      padding: rem(0 0 0 30px);
      margin: 0;
      li {
        list-style: none;
        text-indent: rem(-30px);
        em {
          color:cl(secondary);
          font-size: rem(43px);
          @include media-breakpoint-down(xs) {
            font-size: rem(38px);
          }
        }
      }
    }
  }
}