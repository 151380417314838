.footer {
  background: cl(primary);
  padding: rem(50px 0);
  color:cl(white);
  font-size: rem(16px);
  line-height: 1.3;
  text-align: center;
  font-family: $fontSecondary;
  @include media-breakpoint-down(xs) {
    padding: rem(35px 0);
  }
  a {
    color:cl(white);
  }
  &__logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:rem(15px);
    margin: rem(0 0 20px);
  }
  &__logo {
    max-width:100%;
    &--main {
      width:rem(576px);
      max-width:100%;
    }
    &--side {
      width:rem(118px);
      flex-shrink: 0;
      @include media-breakpoint-down(xs) {
        width:rem(45px);
      }
    }
    &--nav {
      display: none;
      width:rem(150px);
      height: auto;
      top:1rem;
      @extend .pos-centerX;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__address{
    span {
      margin: rem(0 10px);
    }
  }
}

