.procedure {
  &__item {
    padding: rem(95px 0 0)!important;
    margin: rem(0 0 30px);
    &:before {
      left:50%!important;
      top:rem(36px)!important;
      margin-left: rem(-22px)!important;
      width:rem(44px)!important;
      height: rem(44px)!important;
      font-size: rem(30px);
    }
    &:after {
      content: "";
      height: rem(28px);
      width:1px;
      background: #e4e4e4;
      position: absolute;
      left:50%;
      top:0;
    }
    p {
      margin: rem(0 0 10px)!important;
    }
  }
}