.modal-search {
  .modal-dialog {
    max-width: rem(360px);
    @include media-breakpoint-down(xs) {

    }
  }
  .modal-content {
    color:cl(white);
    border: 0;
    border-radius: 0;
    position: relative;
    padding: rem(25px 37px 35px);
    background: cl(primary, .83);
    font-family: $fontSecondary;
  }
  h5 {
    font-size: rem(30px);
    font-weight: normal;
    margin: rem(0 0 16px);
    font-family: $font;
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
  }

}