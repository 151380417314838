.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	box-shadow: rem(0 2px 6px cl(black, .1));
	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: rem(0 50px);
		background:cl(secondary);
		height: rem(30px);
		@include media-breakpoint-down(lg){
			padding: 0 2rem;
		}
		@include media-breakpoint-down(xs){
			padding: 0 1.5rem;
		}
	}
	&__social {
		display: flex;
		align-items: center;
		gap:rem(20px);
		a {
			color:cl(white);
			font-size: rem(18px);
			&:hover {
				text-decoration: none;
				color:cl(primary);
			}
		}
	}
	&__banner {
		img {
			display: block;
			height: rem(30px);
			width:auto;
		}
	}
	&__menu {
		display: flex;
		align-items: center;
		list-style: none;
		gap:rem(35px);
		margin: 0;
		@include media-breakpoint-down(md) {
			height: auto!important;
			flex-shrink: 0;
			border-top:rem(2px solid cl(white, 0.1));
			background: cl(primary);
		}
		a {
			letter-spacing: rem(.1px);
			font-family: $fontSecondary!important;
			color:cl(white);
			font-size: rem(15px)!important;
			&:hover {
				text-decoration: none;
				i {
					margin: rem(0 3px 0 6px);
				}
			}
			@include media-breakpoint-down(md) {
				padding: .8rem 2rem!important;
			}
			i {
				transition: all .3s ease;
				color:cl(red);
				font-size: rem(14px);
				margin: rem(0 9px 0 0);
			}
		}
	}
	&__bottom {
		background: cl(white);
		height: rem(120px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: rem(0 50px);
		@include media-breakpoint-down(lg){
			padding: 0 2rem;
		}
		@include media-breakpoint-down(xs){
			padding: 0 1.5rem;
			gap:rem(20px);
			height: 6rem;
		}
	}
	&__logo {
		flex-shrink: 0;
		display: block;
		&--main {
			width:rem(474px);
			margin: rem(0 auto 15px);
			@include media-breakpoint-down(xs){
				width:18rem;
				margin: 0;
			}
		}
		&--side {
			width:rem(108px);
			@include media-breakpoint-down(xs){
				width:3.5rem;
			}
		}
		&--nav {
			display: none;
			width:18rem;
			height: auto;
			top:1rem;
			@extend .pos-centerX;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}
		img {
			max-width: 100%;
			height: auto;
		}
	}
	&__inner {
		width:100%;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}
		@include media-breakpoint-down(xs) {
			//justify-content: flex-start;
		}
	}
	&__nav {
		width:100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: rem(80px 0 0);
			background: cl(secondary);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		& > ul {
			display: flex;
			align-items: center;
			gap: rem(30px);
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				display: block;
			}
			& > li {
				position: relative;
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;
					border-top:rem(1px solid cl(white, 0.2));
					&:last-child {
						border-bottom:rem(1px solid cl(white, 0.2));
					}
				}
				& > a {
					display: block;
					font-size: rem(24px);
					line-height: rem(26px);
					letter-spacing: rem(.2px);
					color:cl(black);
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1.5rem 2rem;
						text-align: center;
						color:cl(white);
					}
					&:hover {
						text-decoration: none;
						@include media-breakpoint-up(xl) {
							color:cl(primary);
						}
					}
					&.with-drop:after {
						@include media-breakpoint-down(lg) {
							content: '\f107';
							position: absolute;
							top:1.5rem;
							right: 1rem;
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							transition: all .2s linear;
						}
					}
					&.with-drop.opened {
						background: #87221f;
						&:after {
							transform: rotate(180deg);
						}
					}
				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(primary);
						@include media-breakpoint-down(lg) {
							color:cl(secondary);
							background: cl(white);
						}
					}
				}
				&:hover {
					.drop {
						@include media-breakpoint-up(xl) {
							display: block;
						}
					}
				}
				.drop {
					position: absolute;
					top:rem(20px);
					padding-top: rem(12px);
					left:0;
					width:rem(250px);
					font-family: $fontSecondary;
					display: none;
					@include media-breakpoint-down(lg) {
						//display: block;
						position: static;
						width:100%;
						padding: 0;
						margin: 0;
						border-bottom:rem(1px solid cl(white, 0.2));
					}
					ul {
						margin: 0;
						background: cl(primary);
						padding: rem(2px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: #87221f;
						}
						li {
							&:not(:last-child) {
								@include media-breakpoint-up(xl) {
									margin-bottom: 1px;
								}
							}
							a {
								display: block;
								font-size: rem(16px);
								line-height: rem(18px);
								color:cl(white);
								letter-spacing: rem(.2px);
								padding: rem(10px 10px 10px 20px);
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1.2rem 2rem;
									text-align: center;
									border-top:rem(1px solid cl(white, .1));
								}
								&:hover {
									text-decoration: none;
									@include media-breakpoint-up(xl) {
										background: cl(secondary);
									}
								}
							}
							&.active {
								a {
									text-decoration: none;
									background: cl(secondary);
									@include media-breakpoint-down(lg) {
										color:cl(white);
										background: #761b19;
										font-weight: 700;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&__open-nav {
		display: none;
		position: relative;
		padding: 0;
		height:rem(20px);
		width:rem(26px);
		flex-shrink: 0;
		text-indent: -9999px;
		appearance: none;
		border: none;
		cursor: pointer;
		background: none;
		border:0;
		transition: all 0.3s ease  0.3s;
		@include media-breakpoint-up(sm) {
			margin: rem(0 0 0 32px);
		}
		&:focus {
			outline: none;
		}
		@include media-breakpoint-down(lg){
			display: block;
		}
		span,
		span::before,
		span::after {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height:rem(2px);
			background-color: cl(primary);
			content: "";
			transition: all 0.3s ease;
		}
		span {
			top:rem(9px);
			&:before {
				top:rem(-7px);
			}
			&:after{
				bottom:rem(-7px);
			}
		}
		&--active {
			position: absolute;
			top:1.5rem;
			right:1rem;
			&:before {
				display: none;
			}
			span {
				background: none;
				&:before {
					top: 0;
					transform:rotate(45deg);
					background: cl(white);
				}
				&:after{
					bottom: 0;
					transform:rotate(-45deg);
					background: cl(white);
				}
			}
		}
	}
}
