.numbers {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  color:cl(white);
  min-height: rem(700px);
  padding: rem(50px 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-bottom:rem(4px solid cl(white));
  @include media-breakpoint-down(lg) {
    padding: 3rem 1rem 10rem;
  }
  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
  h3 {
    color:cl(white);
    text-transform: uppercase;
    font-size: rem(40px);
    font-weight: normal;
    margin: 0;
    width:100%;
    text-align: center;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top:rem(85px);
      left:rem(116px);
      text-align: left;
    }
    @include media-breakpoint-down(lg) {
      //font-size: rem(30px);
      margin: 0 0 7.5rem;
    }
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
    }
    span {
      font-size: rem(30px);
      transform: none;
    }
  }
  &__circle {
    width:100%;
    @include media-breakpoint-up(sm) {
      width:rem(350px);
      height: rem(350px);
      border:1px solid cl(white);
      border-radius: 50%;
      background: cl(primary);
      position: relative;
      box-shadow:rem(0 17px 101px cl(black, .23));
    }
  }
  &__icon {
    position: absolute;
    left:0;
    width:100%;
    top:0;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width:rem(202px);
    }
  }
  &__hover {
    position: absolute;
    width:100%;
    text-align: center;
    font-size: rem(20px);
    top:rem(-80px);
    span {
      display: block;
      animation: hover 2s infinite linear alternate;
    }
    img {
      display: block;
      margin: 0 auto;
      width:rem(35px);
      height: auto;
      transform: rotate(180deg);
    }
  }
  &__box {
    @include media-breakpoint-down(xs) {
      position: relative;
      width:100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      justify-content: center;
    }
  }
  &__item {

    color:cl(white);
    z-index: 2;
    @include media-breakpoint-up(sm) {
      position: absolute;
    }
    &:hover {
      text-decoration: none;
      color:cl(white);
      em {
        &:before {
          background: cl(secondary);
          transform: scale(1.1);
        }
      }
      & + .numbers__image {
        opacity: 1;
      }
    }
    &--students {
      top:0;
      left:rem(-125px);
      strong {
        font-size: rem(50px);
      }
      em {
        width:rem(177px);
        @include media-breakpoint-down(xs) {
          width:rem(200px);
        }
        &:before {
          right:rem(-11px);
        }
      }
    }
    &--founded {
      top:0;
      right:rem(-205px);
      @include media-breakpoint-up(sm) {
        text-align: right;
      }
      strong {
        font-size: rem(50px);
      }
      em {
        width:rem(260px);
        @include media-breakpoint-down(xs) {
          width:rem(200px);
        }
        &:before {
          @include media-breakpoint-up(sm) {
            left:rem(-11px);
          }
          @include media-breakpoint-down(xs) {
            right:rem(-11px);
          }
        }
      }
    }
    &--grade {
      bottom: rem(80px);
      left:rem(-200px);
      strong {
        @include media-breakpoint-up(sm) {
          font-size: rem(80px);
        }
      }
      em {
        width:rem(210px);
        @include media-breakpoint-down(xs) {
          width:rem(200px);
        }
        &:before {
          right:rem(-11px);
        }
      }
    }
    &--schools {
      bottom: rem(80px);
      right:rem(-255px);
      @include media-breakpoint-up(sm) {
        text-align: right;
      }
      strong {
        @include media-breakpoint-up(sm) {
          font-size: rem(80px);
        }
      }
      em {
        width:rem(265px);
        @include media-breakpoint-down(xs) {
          width:rem(200px);
        }
        &:before {
          @include media-breakpoint-up(sm) {
            left:rem(-11px);
          }
          @include media-breakpoint-down(xs) {
            right:rem(-11px);
          }
        }
      }
    }
    &--states {
      bottom: rem(-125px);
      left:rem(135px);
      @include media-breakpoint-up(sm) {
        text-align: center;
      }
      strong {
        font-size: rem(50px);
      }
      em {
        @include media-breakpoint-up(sm) {
          width:1px;
          height: rem(45px)!important;
          margin: 0 auto!important;
        }
        @include media-breakpoint-down(xs) {
          width:rem(200px);
        }
        &:before {
          @include media-breakpoint-up(sm) {
            left:rem(-11px);
          }
          @include media-breakpoint-down(xs) {
            right:rem(-11px);
          }
        }
      }
    }
    strong {
      display: block;
      line-height: 1;
      font-weight: normal;
      @include media-breakpoint-down(xs) {
        font-size: rem(40px)!important;
      }
    }
    span {
      display: block;
      line-height: 1;
      font-size: rem(24px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        font-size: rem(18px)!important;
      }
    }
    em {
      display: block;
      height: 1px;
      background: cl(white);
      position: relative;
      margin: rem(2px 0 8px);
      &:before {
        content: "";
        position: absolute;
        top:rem(-11px);
        width:rem(22px);
        height: rem(22px);
        border:rem(2px solid cl(white));
        border-radius: 50%;
        background: cl(white);
        transition: all .3s linear;
      }
    }
  }
  &__image {
    position: absolute;
    left:0;
    width:100%;
    top:0;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    opacity: 0;
    transition: all .3s linear;
    @include media-breakpoint-down(xs) {
      opacity: 1;
      position: static;
      width:rem(180px);
      height: rem(180px);
      border:1px solid cl(white);
      border-radius: 50%;
      box-shadow:rem(0 17px 101px cl(black, .23));
      flex-shrink: 0;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
    }
  }
}