.btn {
  @extend .inline-b;
  @extend .ttu;
  background: cl(primary);
  color:cl(white);
  font-size: rem(16px);
  line-height: rem(48px);
  font-family: $fontSecondary;
  font-weight: 700;
  border: rem(1px solid cl(primary));
  cursor: pointer;
  border-radius: 0;
  padding: rem(0 30px);
  letter-spacing: rem(.2px);
  outline: 0;
  white-space: nowrap;
  &:hover {
    border-color:cl(primary);
    background: cl(primary, 0.9);
    color:cl(white);
    text-decoration: none;
  }
  &:focus {
    box-shadow:none;
    color:cl(white);
  }

  &--custom {
    position: relative;
    em {
      position: absolute;
      top:rem(4px);
      left: 50%;
      transform: translateX(-50%);
      width:calc(100% - 0.5rem);
      height:calc(100% - 0.5rem);
      border-top: rem(1px solid cl(white));
      border-bottom: rem(1px solid cl(white));
      transition: all .3s ease;
    }
    i {
      position: absolute;
      left:rem(4px);
      top: 50%;
      transform: translateY(-50%);
      height:calc(100% - 0.5rem);
      width:calc(100% - 0.5rem);
      border-left: rem(1px solid cl(white));
      border-right: rem(1px solid cl(white));
      transition: all .3s ease;
    }
    &:hover {
      @include media-breakpoint-up(xl) {
        em {
          width:0;
        }
        i {
          height:0;
        }
      }
    }
  }
  &--primary-outline {
    background: none;
    color:cl(primary);
    border-color:cl(primary);
    &:hover {
      background: cl(primary, 0.1);
    }
  }
  &--secondary {
    background: cl(secondary);
    border-color:cl(secondary);
    color:cl(white);
    &:hover,
    &:focus{
      background: cl(secondary, .9);
      border-color:cl(secondary);
      color:cl(white);
    }
  }
  &--secondary-outline {
    background: none;
    color:cl(secondary);
    border-color:cl(secondary);
    &:hover {
      background: none;
      border-color:cl(secondary);
      color:cl(secondary);
    }
    em {
      border-color:cl(secondary);
    }
    i {
      border-color:cl(secondary);
    }
  }
  &--black {
    background: cl(black);
    border-color:cl(black);
    &:hover {
      background: cl(black, .8);
    }
  }
  &__icon {
    width:rem(23px);
    height: auto;
    margin: 0 1rem 0.250rem 0!important;
    box-shadow:none!important;
    display: inline-block!important;
    vertical-align: middle;
  }
}