@font-face {
  font-family: 'Palatino';
  src: url('../fonts/Palatino-Roman.eot');
  src: url('../fonts/Palatino-Roman.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Palatino-Roman.woff2') format('woff2'),
  url('../fonts/Palatino-Roman.woff') format('woff'),
  url('../fonts/Palatino-Roman.ttf') format('truetype'),
  url('../fonts/Palatino-Roman.svg#Palatino-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

