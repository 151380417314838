.statistics {
  padding: rem(55px 0);
  position: relative;
  overflow: hidden;
  &__list {
    display: flex;
    flex-wrap: wrap;
    --gap: 2rem;
    gap:var(--gap);
    list-style: none;
    margin: 0;
  }
  &__item {
    --cols: 4;
    flex-basis: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
    text-align: center;
    @include media-breakpoint-down(xs) {
      --cols: 2;
    }
    h3 {
      font-size: rem(18px);
      font-family: $fontSecondary;
      font-weight: 700;
      line-height: 1.4;
      margin: 0;
    }
  }
  &__number {
    width:rem(127px);
    height: rem(127px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $fontSecondary;
    color:cl(primary);
    margin: 0 auto 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding-left: rem(5px);
    strong {
      font-size: rem(52px);
      sup {
        font-size: rem(18px);
      }
    }
  }
}