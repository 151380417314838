.page {
  &__section {
    position: relative;
    overflow: hidden;
    padding-top: rem(70px);
    padding-bottom: rem(50px);
    &--image {
      padding-bottom: 18%;
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    width:100%;
    height: auto;
    z-index: 2;
  }
  &__icon {
    position: absolute;
    width:24rem;
    height: auto;
    right: -35%;
    bottom: -5.25rem;
    opacity: .17;
    z-index: 2;
    @include media-breakpoint-up(xl) {
      width:rem(1200px);
      bottom: rem(-500px);
    }
  }
  &__text {
    font-size: rem(18px);
    line-height: 1.5;
    font-family: $fontSecondary;
    p {
      margin: rem(0 0 30px);
    }
    img {
      display: block;
      max-width: 100%;
      margin: rem(0 auto 45px);
      box-shadow: rem(0 10px 35px cl(black, .15));
    }
    .sign {
      padding-bottom: rem(30px);
      img {
        box-shadow:none;
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
    h2 {
      color:cl(primary);
      font-size: rem(30px);
      font-family: $font;
      margin: rem(0 0 30px);
      &.lg {
        font-size: rem(50px);
      }
    }
    h3 {
      color:cl(primary);
      font-size: rem(26px);
      font-family: $font;
      margin: rem(0 0 25px);
    }
    h4 {
      color:cl(primary);
      font-size: rem(24px);
      font-family: $font;
      margin: rem(0 0 25px);
    }
    h5 {
      color:cl(primary);
      font-size: rem(22px);
      font-family: $font;
      margin: rem(0 0 25px);
    }
    h6 {
      color:cl(primary);
      font-size: rem(20px);
      font-family: $font;
      margin: rem(0 0 25px);
    }
    ul {
      margin: rem(0 0 25px);
      li {
        list-style: none;
        position: relative;
        padding: rem(0 0 0 20px);
        &:before {
          content: "";
          position: absolute;
          left:0;
          top:rem(12px);
          width:rem(5px);
          height: rem(5px);
          background: cl(secondary);
          border-radius: 50%;
        }
      }
    }
    ol {
      counter-reset: item;
      li {
        list-style: none;
        position: relative;
        padding: rem(0 0 5px 42px);
        &:before {
          content: counter(item);
          counter-increment: item;
          position: absolute;
          left:0;
          top:0;
          width:rem(26px);
          height: rem(26px);
          background: cl(secondary);
          border-radius: 50%;
          color:cl(white);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .container {
    position: relative;
    z-index: 3;
  }
  &__map {
    overflow: hidden;
    height: rem(580px);
    @include media-breakpoint-down(xs) {
      height: 28rem;
    }
  }
}