.awards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap:rem(18px);
  padding: rem(30px 0);
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(140px);
    padding: rem(20px);
    background: cl(white);
    box-shadow: rem(0 5px 16px cl(black, .15));
    --cols: 4;
    flex-basis: calc((100% - 1.125rem * (var(--cols) - 1)) / var(--cols));
    @include media-breakpoint-down(xs) {
      --cols: 2;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: contain;
    }
  }
}