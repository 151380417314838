.serve {
  &__list {
    display: flex;
    flex-wrap: wrap;
    --gap: 1.125rem;
    gap:var(--gap);
    list-style: none;
    margin: rem(0 0 50px);
    @include media-breakpoint-down(xs) {
      //--gap: 1rem;
    }
  }
  &__item {
    text-align: center;
    font-family: $fontSecondary;
    line-height: 1.2;
    padding: rem(35px 0);
    background: cl(gray-bg);
    --cols: 4;
    flex-basis: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
    @include media-breakpoint-down(xs) {
      --cols: 2;
    }
    strong {
      display: block;
      font-size: rem(50px);
      color:cl(primary);
      font-family: $font;
      font-weight: normal;
    }
    span {
      font-size: rem(20px);

    }
  }
}