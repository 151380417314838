.info {
  &--home {
    padding: rem(142px 260px 95px 205px);
    overflow: hidden;
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      padding: 4rem 2rem 1rem;
    }
  }
  h2 {
    text-transform: uppercase;
    font-size: rem(30px);
    color:cl(secondary);
    font-weight: normal;
    margin: rem(0 0 25px);
  }
  &__item {
    position: relative;
    display: flex;
    align-items: flex-start;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      margin-bottom: 4rem;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      margin-bottom: 2rem;
    }
    &--reverse {
      @include media-breakpoint-up(sm) {
        flex-direction: row-reverse;
        .info__image {
          width:rem(192px);
          height: rem(192px);
          margin: rem(0 0 0 -115px);
        }
        .info__holder {
          padding: rem(40px 135px 35px 55px);
        }
      }
    }
  }
  &__image {
    overflow: hidden;
    width:rem(330px);
    height: rem(250px);
    margin: rem(0 -95px 0 0);
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    box-shadow: rem(0 10px 59px cl(black, .3));
    @include media-breakpoint-down(xs) {
      margin: 0 auto;
      width:100%;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .2s linear;
    }
  }
  &__holder {
    background: cl(gray-bg);
    font-size: rem(16px);
    line-height: 1.6;
    padding: rem(40px 40px 35px 140px);
    margin: rem(33px 0 0);
    font-family: $fontSecondary;
    @include media-breakpoint-down(xs) {
      padding: 1.5rem;
      margin: 0;
    }
    h3 {
      font-size: rem(30px);
      line-height: 1.3;
      color:cl(primary);
      font-family: $font;
      margin: rem(0 0 20px);
      a {
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
  }
  &__more {
    margin-top: rem(-50px);
    position: relative;
    z-index: 3;
    @include media-breakpoint-down(xs) {
      margin: 0 0 2rem;
    }
  }
  &__icon {
    @extend .pos-centerY;
    @include media-breakpoint-up(xl) {
      width:rem(1200px);
      height: auto;
      right: -35%;
      opacity: .17;
    }
  }
}