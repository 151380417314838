@keyframes hover{
  from {transform: translate(0, 0);}
  to {transform: translate(0, 1rem)}
}
@-webkit-keyframes hover{
  from {transform: translate(0, 0);}
  to {transform: translate(0, 1rem)}
}
/* --- */
@keyframes text-scale{
  0% {
    transform: scale(1.0);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
}
//@-webkit-keyframes hover{
//  from {transform: translate(0, 0);}
//  to {transform: translate(0, 1rem)}
//}