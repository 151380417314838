.contact {
  &--list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:rem(32px 50px);
    margin: rem(0 0 50px);
  }
  &__item {
    padding: rem(32px 32px 16px);
    text-align: center;
    box-shadow: rem(0 5px 16px cl(black, .15));
    --cols: 2;
    flex-basis: calc((100% - 6.25rem * (var(--cols) - 1)) / var(--cols));
    @include media-breakpoint-down(xs) {
      --cols: 1;
    }
    p {
      margin: rem(0 0 16px)!important;
    }
    h3 {
      margin: rem(0 0 16px)!important;
      font-size: rem(22px)!important;
    }
  }
}