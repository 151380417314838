/* banner start */
.banner {
  position: relative;
  background: cl(secondary, 0.5);
  overflow: hidden;
  &--home {
    height: 100vh;
    @include media-breakpoint-down(lg) {
      height: 60rem;
    }
    @include media-breakpoint-down(md) {
      height: 35rem;
      margin-top: 7.5rem;
    }
    @include media-breakpoint-down(xs) {
      height: 30rem;
    }
  }
  &--page {
    margin: rem(150px 0 0);
    height: rem(330px);
    background: cl(secondary);
    align-items: center;
    text-align: center;
    color:white;
    @include media-breakpoint-down(xs) {
      height: 15rem;
      margin: 7.5rem 0 0;
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: rem(100px);
      font-weight: normal;
      letter-spacing: rem(1px);
      margin: 0;
      @include media-breakpoint-down(md) {
        font-size: rem(60px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(40px);
      }
    }
  }
  &__icon {
    @extend .pos-center;
    width:rem(1313px);
    opacity: .05;
    @include media-breakpoint-down(xs) {
      height: 33rem;
    }
  }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    video[poster]{
      object-fit: cover;
    }
  }
  &__text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      margin: 0;
    }
  }
  &__ttl {
    display: block;
    font-size: rem(100px);
    letter-spacing: rem(1px);
    font-weight: normal;
    margin: rem(0 0 5px);
    text-transform: uppercase;
    @include media-breakpoint-down(xs) {
      font-size: rem(40px);
    }
  }
  &__txt {
    display: block;
    font-size: rem(60px);
    letter-spacing: rem(1px);
    text-transform: uppercase;
    @include media-breakpoint-down(xs) {
      font-size: rem(20px);
    }
  }
}
/* banner end */

