.faq {
  .card {
    border:0;
    border-radius: 0;
    margin: rem(0 0 6px);
    background: none;
    &-header {
      padding: 0;
      border:0;
      background: none;
    }
    &-icon {
      position: absolute;
      left:rem(20px);
      top:rem(18px);
      font-size: rem(18px);
    }
    &-opener {
      font-size: rem(18px);
      line-height: 1.4;
      color:cl(primary);
      background: cl(gray-bg);
      font-family: $fontSecondary;
      font-weight: 700;
      display: block;
      width:100%;
      text-align: left;
      border:0;
      position: relative;
      padding: rem(18px 60px);
      &:focus {
        outline: none;
        color:cl(primary);
      }
      &:before {
        content: "";
        position: absolute;
        right:rem(20px);
        top:rem(29px);
        width:rem(24px);
        height: rem(2px);
        background: cl(primary);
      }
      &:after {
        content: "";
        position: absolute;
        right:rem(31px);
        top:rem(18px);
        height:rem(24px);
        width: rem(2px);
        background: cl(primary);
        opacity: 0;
      }
      &.collapsed {
        color:cl(text);
        &:after {
          opacity: 1;
        }
        &:hover,
        &:focus {
          color:cl(primary);
        }
      }
    }
    &-body {
      padding: rem(18px 60px);
      font-size: rem(16px);
      line-height: 1.6;
      margin-top: rem(6px);
      box-shadow: rem(0 5px 16px cl(black, .15));
      position: relative;
      p {
        margin: rem(0 0 5px);
      }
    }
  }
}