.about {
  &--home {
    padding: rem(65px 190px 165px 260px);
    overflow: hidden;
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      padding: 4rem 2rem 6rem;
    }
  }
  &__slogan {
    font-size: rem(30px);
    line-height: 1.5;
    color:cl(primary);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      margin-bottom: 3rem;
      font-size: rem(26px);
    }
    h2 {
      //text-transform: uppercase;
      //color:cl(secondary);
      //font-size: rem(50px);
      //font-weight: normal;
      //margin: rem(0 0 25px);
    }
    p {
      margin: rem(0 0 30px);
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    text-align: right;
    padding: rem(40px 0 0);
    z-index: 2;
    @include media-breakpoint-up(sm) {
      align-items: flex-end;
      gap:rem(36px);
    }
  }
  &__link {
    display: inline-block;
    text-transform: uppercase;
    font-size: rem(30px);
    position: relative;
    z-index: 3;
    &:before {
      content: "";
      position: absolute;
      top:100%;
      right:0;
      height: 1px;
      width:100%;
      background: cl(primary);
      transition: all .3s linear;
    }
    &:hover {
      text-decoration: none;
      color:cl(secondary);
      &:before {
        background: cl(secondary);
        @include media-breakpoint-up(sm) {
          width:rem(340px);
        }
      }
      & + .about__image {
        opacity: 1;
      }
    }
  }
  &__image {
    width:rem(350px);
    height: rem(300px);
    background: cl(black);
    transition: all .3s linear;
    &:nth-child(2) {
      opacity: 1;
    }
    @include media-breakpoint-up(sm) {
      opacity: 0;
      position: absolute;
      top:0;
      right: rem(305px);
    }
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
      margin-top: rem(5px);
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      opacity: .83;
      box-shadow: rem(0 10px 59px cl(black, .3));
    }
  }
  &__icon {
    @extend .pos-centerY;
    @include media-breakpoint-up(xl) {
      width:rem(1200px);
      height: auto;
      right: -35%;
      opacity: .17;
    }
  }
}