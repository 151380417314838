.testimonials {
  &--bottom {
    position: relative;
    overflow: hidden;
    padding: rem(50px 0 70px);
  }
  .container {
    position: relative;
    z-index: 2;
  }
  &__icon {
    @extend .pos-centerY;
    @include media-breakpoint-up(xl) {
      width:rem(1200px);
      height: auto;
      right: -35%;
      opacity: .17;
    }
  }
  &__article {
    font-size: rem(18px);
    line-height: 1.5;
    font-family: $fontSecondary;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    margin: rem(0 0 30px);
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__photo {
    @include circle(172px);
    border:rem(6px solid cl(gray-bg));
    background: cl(gray-bg);
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    &--sm {
      @include circle(120px);
      margin: rem(32px -58px 0 0);
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 -58px);
      }
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &__holder {
    background: cl(gray-bg);
    padding: rem(30px 50px 25px 70px);
    position: relative;
    @include media-breakpoint-down(xs) {
      padding: rem(70px 30px 25px 30px);
    }
  }
  &__title {
    display: flex;
    align-items: flex-end;
    gap:rem(25px);
    margin: rem(-66px 0 16px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: -3rem 0 2rem;
    }
    h2 {
      color:cl(primary);
      font-size: rem(30px);
      font-family: $font;
      margin: rem(0 0 50px);
      @include media-breakpoint-down(xs) {
        margin: 0;
      }
    }
  }
  &__text {
    margin: rem(0 0 25px);
    position: relative;
    z-index: 2;
  }
  &__author {
    position: relative;
    z-index: 2;
    p {
      margin: 0;
    }
  }
  &__quote {
    position: absolute;
    right:rem(38px);
    top: rem(40px);
    width:rem(70px);
    height: auto;
    transform: rotate(180deg);
    &--bottom {
      top:auto;
      bottom: rem(30px);
    }
  }
}