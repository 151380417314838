.vision {
  &__video {
    display: block;
    width:100%;
  }
  &--home {
    display: flex;
    align-items: center;
    color:cl(white);
    font-size: rem(20px);
    line-height: 1.5;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      margin: rem(-53px 0);
      font-size: rem(28px);
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
  &__video {
    video {
      display: block;
      width:100%;
      height: auto;
    }
    video[poster]{
      object-fit: cover;
    }
  }
}