.school {
  &__filter {
    .nav {
      &-tabs {
        display: flex;
        --gap-side: 1.438rem;
        gap:2rem var(--gap-side);
        margin: rem(0 0 30px);
        border:0;
        @include media-breakpoint-down(xs) {
          --gap-side: 1rem;
        }
      }
      &-item {
        --cols: 3;
        flex-basis: calc((100% - var(--gap-side) * (var(--cols) - 1)) / var(--cols));
        margin: 0;
        padding: 0!important;
        &:before {
          display: none!important;
        }
      }
      &-link {
        border:1px solid cl(primary);
        padding: rem(10px);
        font-size: rem(15px);
        letter-spacing: rem(1px);
        text-transform: uppercase;
        font-weight: 700;
        font-family: $fontSecondary;
        text-align: center;
        border-radius: 0;
        &:hover,
        &:focus {
          text-decoration: none;
          border-color:cl(secondary);
          color:cl(secondary);
        }
        &.active {
          border-color:cl(secondary);
          background: cl(secondary);
          color:cl(white);
          &:hover{
            border-color:cl(secondary);
            background: cl(secondary, .8);
            color:cl(white);
          }
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(14px);
          padding: rem(5px);
        }
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    --gap-side: 1.438rem;
    gap:2rem var(--gap-side);
    margin: rem(0 0 30px);
  }
  &__item {
    --cols: 3;
    flex-basis: calc((100% - var(--gap-side) * (var(--cols) - 1)) / var(--cols));
    background: cl(gray-bg);
    transition: all .2s linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &:hover {
      background: cl(white);
      box-shadow: rem(0 5px 16px cl(black, .15));
    }
    @include media-breakpoint-down(xs) {
      --cols: 1;
    }
  }
  &__image {
    height: rem(140px);
    overflow: hidden;
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      box-shadow: none;
    }
  }
  &__holder {
    padding: rem(20px);
    font-size: rem(14px);
    line-height: 1.4;
    h3 {
      font-size: rem(22px)!important;
      margin: rem(0 0 10px)!important;
    }
    p {
      margin: 0!important;
    }
    a {
      color:cl(text);
    }
  }
  &__web{
    display: block;
    margin-bottom: rem(15px);
  }
  &__view {
    padding: rem(0 20px 20px);
    a {
      display: block;
      border:1px solid cl(secondary);
      color:cl(secondary);
      text-transform: uppercase;
      font-size: rem(15px);
      letter-spacing: rem(1px);
      padding: rem(10px);
      font-weight: 700;
      text-align: center;
      transition: all .2s linear;
      &:hover,
      &:focus{
        text-decoration: none;
        background: cl(secondary);
        color:cl(white);
      }
    }
  }
  &__reset {
    position: absolute;
    right:0;
    top:0;
    height:100%;
    padding: rem(0 5px 0 0);
    color:#a9a4a4;
    background: none;
    border:none;
    &:hover,
    &:focus {
      color:cl(primary);
      outline: none;
    }
  }
  &__find {
    &--background {
      padding: rem(40px 40px 35px);
      background: cl(primary);
      font-family: $fontSecondary;
      max-width: rem(360px);
      margin: 0 auto;
      color:cl(white)
    }
    .form {
      &-group {
        i {
          @extend .pos-centerY;
          left:rem(15px);
          color:#373737;
        }
      }
      &-control {
        font-family: $fontSecondary;
        padding-left: rem(40px);
        height: rem(50px);
        border-radius: rem(3px);
        border:0;
        &:focus {
          & + .form-drop {
            //display: block;
          }
        }
      }
      &-drop {
        position: absolute;
        left:0;
        width:100%;
        top:calc(100% - .313rem);
        padding-top: 0.375rem;
        z-index: 10;
        display: none;
        &-inner {
          background: cl(white);
          border-radius: rem(3px);
          padding: rem(5px 0);
          font-size: rem(15px);
          line-height: 1.2;
          max-height: rem(260px);
          overflow-y: auto;
          a {
            display: block;
            padding: rem(5px 15px);
            &:hover {
              text-decoration: none;
              background: cl(primary);
              color:cl(white);
            }
          }
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    gap:rem(16px);
    padding: rem(23px);
    background: cl(gray-bg);
    margin-bottom: rem(32px);
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
  &__text {
    width:100%;
    font-size: rem(15px);
    p {
      margin: rem(0 0 10px)!important;
    }
  }
  &__buttons {
    flex-shrink: 0;
    width:100%;
    display: flex;
    gap:rem(18px);
    justify-content: space-between;
    @include media-breakpoint-down(xs) {

    }
    @include media-breakpoint-up(sm) {
      width: rem(170px);
      flex-direction: column;
    }
    .btn {
      width:calc(50% - 0.563rem);
      @include media-breakpoint-up(sm) {
        width: 100%
      }
    }
  }
  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap:rem(20px);
    margin-bottom: rem(40px);
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
  &__back {
    text-transform: uppercase;
    font-size: rem(15px);
    color:cl(secondary);
    font-weight: 700;
    letter-spacing: rem(1px);
    i {
      font-size: rem(18px);
    }
  }
}