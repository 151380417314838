.slider {
  &--gallery {
    height: rem(500px);
    @include media-breakpoint-down(xs) {
      height: 22rem;
    }
    .slick-list,
    .slick-track,
    .item {
      height: 100%;
    }
    img {
      width:100%;
      height: calc(100% - 2.8125rem);
      object-fit: contain;
      box-shadow: none!important;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:rem(5px);
    margin: rem(-15px 0 15px);
    position: relative;
    z-index: 200;
  }
  &__paging {
    b {
      color:cl(primary);
    }
  }
  &__prev,
  &__next {
    color:cl(primary);
    font-size: rem(40px);
    line-height: 1;
    background: none;
    border:0;
    padding: rem(5px);
    &:hover {
      color:cl(secondary);
    }
  }
}