.staff {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: rem(0 0 32px);
    @include media-breakpoint-down(xs) {
      flex-direction: column-reverse;
      gap:rem(30px);
    }
    h2 {
      color:cl(secondary);
      text-transform: uppercase;
      font-size: rem(30px);
      font-weight: normal;
      font-family: $font;
      margin: 0;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    --gap-side: 3.125rem;
    gap:1.875rem var(--gap-side);
    list-style: none;
    margin: rem(0 0 50px);
    @include media-breakpoint-down(xs) {
      --gap-side: 1.875rem;
    }
    &--sm {
      .staff__item {
        @include media-breakpoint-up(xl) {
          --cols: 4;
        }
      }
    }
  }
  &__item {
    text-align: center;
    font-family: $fontSecondary;
    line-height: 1.2;
    --cols: 5;
    flex-basis: calc((100% - var(--gap-side) * (var(--cols) - 1)) / var(--cols));
    padding: 0!important;
    &:before {
      opacity: 0;
    }
    @include media-breakpoint-down(lg) {
      --cols: 4;
    }
    @include media-breakpoint-down(xs) {
      --cols: 2;
    }
    h3 {
      color:cl(primary);
      font-size: rem(22px);
      font-family: $font;
      margin: rem(0 0 5px);
      a {
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
    p {
      margin: 0;
    }
  }
  &__photo {
    display: block;
    height: rem(180px);
    overflow: hidden;
    margin: rem(0 0 15px);
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .2s linear;
    }
  }
}