.news {
  &__list {
    display: flex;
    flex-wrap: wrap;
    --gap-side: 1.375rem;
    gap:3.438rem var(--gap-side);
    list-style: none;
    margin: rem(0 0 50px);
  }
  &__item {
    font-family: $fontSecondary;
    font-size: rem(16px);
    padding: rem(25px);
    line-height: 1.6;
    background: cl(gray-bg);
    --cols: 3;
    flex-basis: calc((100% - var(--gap-side) * (var(--cols) - 1)) / var(--cols));
    transition: all .2s linear;
    &:hover {
      box-shadow: rem(0 5px 16px cl(black, .15));
      background: cl(white);
      .news__image {
        box-shadow:none;
      }
    }
    @include media-breakpoint-down(md) {
      --cols: 2;
    }
    @include media-breakpoint-down(xs) {
      --cols: 1;
    }
    h2 {
      color:cl(primary);
      font-size: rem(22px);
      font-family: $font;
      margin: rem(0 0 10px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      a {
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
    p {
      margin: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
  &__image {
    display: block;
    height: rem(230px);
    overflow: hidden;
    margin: rem(-50px 0 25px);
    box-shadow: rem(0 5px 16px cl(black, .15));
    transition: all .2s linear;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .2s linear;
    }
  }
}