.map {
  &-holder {
    position: relative;
    overflow: hidden;
    &--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(156px 102px 190px);
      gap:rem(120px);
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        padding: 3rem 2rem 5rem;
      }
    }
  }
  &-area {
    width:100%;
    height: 100%;
    left:0;
    top:0;
    position: absolute;
    z-index: 1;
    @include media-breakpoint-down(xs) {
      position: relative;
      height: rem(600px);
    }
    iframe {
      display: block;
      width:100%;
      height: 100%;
    }
  }
  &-info {
    //position: absolute;
    //width:rem(400px);
    padding: rem(20px);
    //background: cl(white);
    //box-shadow:rem(0 50px 100px cl(black, .32));
    font-family: $fontSecondary;
    font-size: rem(18px);
    line-height: 1.4;
    //&:before {
    //  content: "";
    //  width: 0;
    //  height: 0;
    //  top: 100%;
    //  @extend .pos-centerX;
    //  border-left: rem(15px solid transparent);
    //  border-right: rem(15px solid transparent);
    //  border-top: rem(15px solid cl(white));
    //}
    //&-close {
    //  position: absolute;
    //  top:rem(15px);
    //  right:rem(15px);
    //  width:rem(18px);
    //  height: rem(18px);
    //  border:0;
    //  background: url(../img/close.png) no-repeat;
    //  background-size: contain;
    //  overflow: hidden;
    //  text-indent: -9999px;
    //}
    h3 {
      font-size: rem(24px);
      font-family: $font;
      font-weight: normal;
    }
    &-link {
      padding: rem(20px 0 25px);
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &-addr {
    display: flex;
    margin: rem(0 0 5px);
    dt {
      width:rem(30px);
      flex-shrink: 0;
      font-size: rem(18px);
    }
    dd {
      margin: 0;
    }
  }
  &-sidebar {
    position: relative;
    margin-left:rem(102px);
    width:rem(380px);
    padding: rem(116px 0 100px);
    height: 100%;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      margin-left:2rem;
    }
    @include media-breakpoint-down(md) {
      width:rem(300px);
    }
    @include media-breakpoint-down(xs) {
      width:auto;
      margin-right:2rem;
    }
    &-inner {
      height: 100%;
      background: cl(secondary);
      position: relative;
      padding: rem(40px 35px);
      box-shadow:rem(0 50px 100px cl(black, .32));
      &:before {
        content: "";
        position: absolute;
        right:rem(-22px);
        bottom:rem(-26px);
        width:2px;
        height: calc(100% + 3.813rem);
        background: cl(secondary);
      }
      &:after {
        content: "";
        position: absolute;
        left:0;
        bottom:rem(-26px);
        width:calc(100% + 1.375rem);
        height: 2px;
        background: cl(secondary);
      }
    }
    &-title {
      position: absolute;
      top:rem(-58px);
      left:0;
      width:100%;
      &:before {
        content: "";
        position: absolute;
        right:rem(-22px);
        bottom:rem(17px);
        height:2px;
        width: 65%;
        background: cl(secondary);
        @include media-breakpoint-down(md) {
          width: 50%
        }
      }
      h2 {
        font-size: rem(40px);
        line-height: rem(42px);
        font-family: $font;
        margin: 0;
        font-weight: normal;
        color:cl(secondary);
      }
    }
    .form {
      .custom-select {
        background-color: transparent;
        color:cl(white);
        background-image: url(../img/select-arrow-white.png);
        &:focus {
          background-color: transparent;
        }
        option {
          color: cl(primary)!important;
        }
      }
    }
    &-list {
      list-style: none;
      li {
        border-top:1px solid cl(white, .21);
        font-size: rem(20px);
        line-height: 1.2;
      }
      a {
        display: block;
        color:cl(white);
        padding: rem(15px 5px 15px 10px);
        &.current,
        &:focus,
        &:hover {
          text-decoration: none;
          background:cl(primary);
        }
      }
    }
    .text-scroll-min {
      height: rem(280px);
      @include media-breakpoint-down(lg) {
        overflow: auto;
      }
    }
  }
  &-address {
    font-size: rem(18px);
    line-height: 1.5;
    font-family: $fontSecondary;
    @include media-breakpoint-down(md) {
      width:100%;
    }
    h2 {
      font-size: rem(36px);
      font-family: $font;
      margin: rem(0 0 30px);
      font-weight: normal;
      text-transform: uppercase;
      color:cl(primary);
    }
    h3 {
      font-size: rem(30px);
      font-family: $font;
      margin: rem(0 0 15px);
      font-weight: normal;
      color:cl(primary);
    }
    &-email {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-box {
    width:rem(900px);
    height: rem(650px);
    flex-shrink: 0;
    position: relative;
    box-shadow: rem(0 10px 35px cl(black, .15));
    @include media-breakpoint-down(md) {
      width:100%;
    }
    @include media-breakpoint-down(xs) {
      height: 35rem;
    }
    //&:before {
    //  content: "";
    //  position: absolute;
    //  right:rem(-22px);
    //  bottom:rem(-26px);
    //  width:2px;
    //  height: calc(100% + 3.813rem);
    //  background: cl(primary);
    //}
    //&:after {
    //  content: "";
    //  position: absolute;
    //  right:rem(-22px);
    //  bottom:rem(-26px);
    //  width:80%;
    //  height: 2px;
    //  background: cl(primary);
    //}
    &-title {
      position: absolute;
      top:rem(-58px);
      left:0;
      width:100%;
      padding-right: rem(177px);
      @include media-breakpoint-down(xs) {
        padding-right: rem(100px);
      }
      &:before {
        content: "";
        position: absolute;
        right:rem(-22px);
        bottom:rem(17px);
        height:2px;
        width: rem(172px);
        background: cl(primary);
        @include media-breakpoint-down(xs) {
          width: rem(100px);
        }
      }
      h4 {
        font-size: rem(40px);
        line-height: rem(42px);
        font-family: $font;
        margin: 0;
        font-weight: normal;
        text-align: right;
      }
    }
    &-inner {
      width:100%;
      height: 100%;
      overflow: hidden;
      iframe {
        display: block;
        width:100%;
        height: 100%;
      }
    }
    .map-addr {
      dt {
        font-size: rem(18px);
      }
    }
  }
}